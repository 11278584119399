// - - - - - - - - - - - - - - - - - -

// Component - Post Card

// - - - - - - - - - - - - - - - - - -

.post__card {
  transition: all 120ms;
  box-sizing: content-box;

  &:hover {
    transform: translateY(-4px);
  }

  .post__link {
    display: block;
  }

  .post__image {
    width: 100%;
    height: 220px;
    background-size: cover;
    background-position: center;
    margin-bottom: 16px;
  }

  .post__date {
    margin-bottom: 6px;
  }

  .post__author {
    display: flex;
    padding-bottom: 8px;
   }

  .post__author-avatar {
    width: 28px;
    height: 28px;
    flex:0 0 auto;
    border-radius:50%
  }

  .post__author-info {
    padding-left: 12px;
    font-size: $label-size;
   }

  @include mq("460px") {
    .post__image {
      height: 250px;
    }
  }
}
