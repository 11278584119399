// - - - - - - - - - - - - - - - - - -

// Section - Hero

// - - - - - - - - - - - - - - - - - -


.tier {
	margin-bottom: 30px;
	margin-top: 20px;
}

.tier_img {
	float: left;
	margin-right: 10px;
	margin-bottom: 20px;
	margin-top: -7px;
	margin-right: 15px;
	width: 55px;
}