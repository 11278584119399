// - - - - - - - - - - - - - - - - - -

// Page - Blog

// - - - - - - - - - - - - - - - - - -

.page-blog {
  .all-posts {
    margin-bottom: 48px;

    .post-new {
      margin-bottom: 16px;

      .grid {
        grid-gap: 24px;
      }

      .post__image {
        width: calc(100% + 60px);
        height: 220px;
        position: relative;
        margin-left: -30px;
        margin-bottom: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &--new {
          &::before {
            content: "NEW";
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            background-color: $accent-color;
            color: white;
            border: 2px solid white;
            border-radius: 100%;

            font-family: $title-font;
            font-weight: $bold-weight;
            font-size: 10px;

            position: absolute;
            top: -28px;
            right: 30px;

            transform: rotate(30deg);
          }
        }
      }

      .post__content-wrap {
        height: 100%;
        justify-self: center;
        align-self: center;
      }

      .post__content {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 456px;
      }

      .post__author {
        display: flex;
        padding-bottom: 8px;
       }
    
      .post__author-avatar {
        width: 28px;
        height: 28px;
        flex:0 0 auto;
        border-radius:50%
      }
    
      .post__author-info {
        padding-left: 12px;
        font-size: $label-size;
       }

      .post__date {
        margin-bottom: 8px;
      }

      .post__title {
        margin-bottom: 12px;
      }

      .post__description {
        margin-bottom: 16px;
      }
    }

    .posts__list {
      grid-row-gap: 56px;
    }
  }

  .pagination {
    margin-bottom: 48px;

    .wrap {
      display: flex;
      justify-content: center;
    }

    a {
      margin: 0 10px;
    }
  }

  @include mq(tabletp) {
    .all-posts {
      margin-bottom: 80px;

      .posts__list {
        grid-template-columns: 1fr 1fr;
      }

      .post-new {
        grid-column: 1/3;

        .grid {
          grid-template-columns: 1fr 1fr;
          grid-gap: 30px;
        }

        .post__image {
          width: 100%;
          margin-left: 0;

          &::before {
            width: 56px;
            height: 56px;
            right: -28px;
            font-size: 12px;
            border: 3px solid white;
          }
        }

        .post__title {
          margin-bottom: 24px;
        }

        .post__description {
          margin-bottom: 24px;
        }
      }
    }

    .pagination a {
      margin: 0 20px;
    }
  }

  @include mq(tabletl) {
    .all-posts {
      margin-bottom: 100px;
      
      .post-new {
        grid-column: 1/4;

        .post__image {
          height: 406px;
        }
      }
			
      .posts__list {
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 80px;
      }
    }
  }
}
