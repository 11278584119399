// - - - - - - - - - - - - - - - - - -

// Page - Blog

// - - - - - - - - - - - - - - - - - -

.header--single {
	padding: 40px 0 48px;

	.header__title {
		margin-bottom: 16px;
	}

	.header__subtitle {
		max-width: 556px;
	}

	@include mq(tabletp) {
		padding: 56px 0 120px;
	}
}

.usp {
	border-top: 2px solid $accent_color;
	margin-top: 0px;
	padding-top: 1em;
	padding-bottom: 1em;
}

.partner_logos {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
	justify-items: center;
	align-items: center;

	&.--small {
		grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
	}

	> a {
		padding: 20px;
		object-fit: contain;
		filter: grayscale(75%);
		opacity: 0.75;
	}

	> a:hover {
		filter: none;
		opacity: 1;
	}

	> img {
		padding: 20px;
		object-fit: contain;
		filter: grayscale(75%);
		opacity: 0.75;
	}

	> img:hover {
		filter: none;
		opacity: 1;
	}
}

.team_pictures {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

	> div {
		padding: 16px;
		vertical-align: middle;
		display: flex;

		img {
			object-fit: contain;
			margin: auto;
		}

		.fa-linkedin-in {
			float: right;
			margin-top: 5px;
		}
	}
}

/* Sidebar for knowledgebase */
.sidenav {
	width: 300px;
	top: 38px;
	position: sticky;
	margin-bottom: 30px;
	background: #eee;
	float: left;
	padding: 15px 4px 30px 30px;
	margin-left: -120px;
	display: block;
	font-size: 16px;
	letter-spacing: 0.04;
    line-height: 26px;
}
.sidenav li:hover {
	color: $accent-color;
}

.main {
	margin-left: 210px;
	margin-right: 20px;
	margin-bottom: 80px;
	padding: 0px 10px;
}

.column {
	float: left;
	width: 33.33%;
	padding: 0px;
	margin: 0;
	column-gap: 40px;
	padding: 10px;
	text-align: center;
}

.row:after {
	content: "";
	display: table;
	clear: both;
}

/* Tooltip for Terminology list */
.tooltip {
	position: relative;
	display: inline-block;
	color:#888888;
	cursor: pointer;
	z-index: 1;
}
  .tooltip:hover{
	color: $accent-color;
  }

  .tooltip .tooltiptext {
	visibility: hidden;
	width: 400px;
	background-color: #eee;
	color: #000000;
	text-align: left;
	font-size: 16px;
	line-height: 20px;
	border-radius: 5px;
	padding: 5px 5px;
	z-index: 2;


	/* Position the tooltip */
	position: absolute;
	top: -50px;
  }

  .tooltip:hover .tooltiptext {
	visibility: visible;
  }
@media screen and (max-width: 1400px) {
	.sidenav {
		position: static;
		margin: 0px 10px 20px 0px;
		width: 90%;
	}
	.main {
		margin-left: 0px;
		width: 90%;
	}
	.column {
		width: 100%;
	}

	.tooltip .tooltiptext{
	width:270%;
	position: absolute;
	bottom: auto;
	top: 80%;
	transform: translateX(-50%);
	}
}

.center {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 90px;
}
.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
									  supported by Chrome and Opera */
}

.noselect:focus {
	outline: none;
}

div .docs{
	font-size: 18px;
	letter-spacing: 0.04;
    line-height: 28px;
}
.docs ul li{
	list-style-position: outside;
	margin-left: 25px;
}

.docs ol li{
	list-style-position: outside;
	margin-left: 25px;
}

.flag img{
	width: 10px;
	vertical-align:text-top;
	display: inline-block;
	position: relative;
	left: 3px;
}
.flagSidenav img{
	width: 10px;
	vertical-align:text-top;
	display: inline-block;
	position: relative;
	left: 0px;
	top: -2px;
}
summary{
	list-style: none;
}

summary:before {
	content: ' ';
	border: solid #474747;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 0 5px 5px 0;
	transform: rotate(45deg);
	margin-right:5px;
	margin-bottom: 1px;
 }

 details[open] > summary:before{
	content: ' ';
	border: solid #474747;
	border-width: 0 2px 2px 0;
	display: inline-block;
	padding: 0 5px 5px 0;
	transform: rotate(-135deg);
	margin-right:5px;
	margin-bottom: 1px;
 }

 .notes {
	background-color: #09d2be1f;
	color: #383838;
	text-align: left;
	font-style: italic;
	font-size: 16px;
	line-height: 20px;
	border-radius: 10px;
	margin-left: 30px;
	margin-right: 30px;
	padding: 10px 10px;
 }

.notesIcon{
 width: 18px;
 height: 18px;
 vertical-align:text-bottom;
 display: inline-block;
 margin-right: 10px;
}

.feature {
	background-color: #09d2be51;
	color: #383838;
	font-size: 12px;
	line-height: 14px;
	border-radius: 10px;
	margin-left: 5px;
	margin-right: 5px;
	padding: 5px 5px;
	max-width:fit-content;
 }

 .fix {
	background-color: #7ea7a35a;
	color: #383838;
	font-size: 12px;
	line-height: 14px;
	border-radius: 10px;
	margin-left: 5px;
	margin-right: 5px;
	padding: 5px 5px;
	max-width:fit-content;
 }

 .new{
	content: "NEW";
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	background-color: $accent-color;
	color: white;
	border: 2px solid white;
	border-radius: 100%;

	font-family: $title-font;
	font-weight: $bold-weight;
	font-size: 10px;

	position:absolute;
	top: 16px;
	left: 135px;

	transform: rotate(30deg);
 }

 table, td, th, td .docs{
	text-align: left;
	vertical-align: top;
	border: 1px solid black;
	border-collapse: collapse;
 }
 /* Styles the thumbnail */

a.lightbox img {
	margin: auto;
	}

	/* Styles the lightbox, removes it from sight and adds the fade-in transition */

.lightbox-target {
	position: fixed;
	left: 0;
	z-index: 1002;
	width: 100vw;
	height: 100vh;
	background-color: white;
	display: none;
	-webkit-transition: opacity .5s ease-in-out;
	-moz-transition: opacity .5s ease-in-out;
	-o-transition: opacity .5s ease-in-out;
	transition: opacity .5s ease-in-out;
	overflow: hidden;

}

	/* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

.lightbox-target img {
	margin: auto;
	position: fixed;
	padding: 3%;
	top: 0;
	left:0;
	right:0;
	bottom: 0;
	max-height: 0%;
	max-width: 0%;
	box-sizing: border-box;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;

}

button.button--close-modal {
	position: absolute;
	top: 30px;
	right: 30px;
}

/* Styles the close link, adds the slide down transition */

a.lightbox-close {
	display: block;
	width:50px;
	height:50px;
	box-sizing: border-box;
	background: rgba(255, 255, 255, 0);
	color: black;
	text-decoration: none;
	position: fixed;
	top: -80px;
	right: 0;
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
}

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

.lightbox-target:target {
	display: block;
	top: 0;
	bottom: 0;
	  overflow:scroll;
}

.lightbox-target:target img {
	max-height: 100%;
	max-width: 100%;
}

.lightbox-target:target a.lightbox-close {
	top: 0;
}

/* Table style */
.table-kb {
	width: 100%;
	border-collapse: collapse;
	margin: 20px 0;
	font-size: 16px;
	font-family: Arial, sans-serif;
  }
  th, td {
	border: 1px solid #ddd;
	padding: 10px;
	text-align: left;
  }
  th {
	background-color: #eee;
  }
  tr:nth-child(even) {
	background-color: #f9f9f9;
  }
  tr:hover {
	background-color: #f1f1f1;
  }


/* Search bar/modal */
.modal {
	display: none; 
	position: fixed; 
	z-index: 10000; 
	padding-top: 100px; 
	left: 0;
	top: 0;
	width: 100%; 
	height: 100%; 
	overflow: auto; 
	background-color: rgb(0,0,0); 
	background-color: rgba(0,0,0,0.4); 
}

/* Modal Content */
.modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
}

/* The Close Button */
.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #09d2bd;
	text-decoration: none;
	cursor: pointer;
}

.sidenav-search {
	font-size: 16px;
	letter-spacing: inherit;
	line-height: 26px;
}
.sidenav-search:hover {
	color: #09d2bd;
}

#lunrBtn {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-family: $title-font;
	font-size: 20px;
}

#lunrBtn i, #lunrBtn svg {
    margin-right: 0.5rem;
    color: #09d2bd;
}

#lunrBtn:hover {
    color: #09d2bd;  
}

#lunrsearchresults {padding-top: 0.2rem;}
	.lunrsearchresult {padding-bottom: 1rem;
		list-style: none; 
		padding-left: 1rem; 
		display: flex; 
		align-items: center; 
	}
	.lunrsearchresult .bullet {
		margin-right: 8px; 
		font-size: 1.5rem;
		color: #09d2bd; 
	  }
	.lunrsearchresult .title {color: #09d2bd;}
	.lunrsearchresult .url {color: silver;}
	.lunrsearchresult a {display: block; color: #777;}
	.lunrsearchresult a:hover, .lunrsearchresult a:focus {text-decoration: None;}
	.lunrsearchresult a:hover .title {text-decoration: underline;}
	.lunrsearchresult:hover {
		background-color: #f0f0f0;  
		cursor: pointer;  
		transition: background-color 0.3s ease; }
	.lunrsearchresult .title:hover {
			font-weight: bold;  
		}
