// - - - - - - - - - - - - - - - - - -

// Header + menu

// - - - - - - - - - - - - - - - - - -

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Header

.header--main {
  padding: 30px 0;

  .header__logo {
    display: block;
    align-self: center;
    width: $logo-width;
    position: relative;
    z-index: 10;
  }

  .header__title {
    font-family: $title-font;
    font-size: $sitetitle-small;
    font-weight: 600;
    letter-spacing: $sitetitle-spacing;
    line-height: 1;
    color: $text-dark-color;

    @include mq(tabletp) {
      font-size: $sitetitle-medium;
    }

    @include mq(laptop) {
      font-size: $sitetitle-large;
    }

    &:hover {
      color: $accent-color;
    }
  }

  .header__menu {
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    position: fixed;
    top: 10px;
    left: 0;
    z-index: 1000;

    opacity: 0;
    visibility: hidden;
    @include transition(all 0.6s cubic-bezier(0.2, 0.3, 0, 1));
  }

  .header__cta {
    justify-self: end;
    display: none;
  }

  @include mq(tabletp) {
    padding: 48px 0;

    .wrap {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .header__menu {
      background-color: rgba($color: #ffffff, $alpha: 0.0);
      width: initial;
      height: initial;
      position: relative;
      background-color: none;
      justify-self: center;
      opacity: 1;
      visibility: visible;
      top: 0;
      align-self: center;
    }

    .header__menu,
    .header__cta {
      display: block;
    }
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Menu

.menu__toggle {
  position: absolute;
  z-index: 1002;
  width: 40px;
  height: 40px;
  top: 50%;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include translate(0, -50%);
  cursor: pointer;
  background-color: transparentize($accent-color, 0.92);
  border-radius: 8px;

  &:active {
    background-color: transparentize($accent-color, 0.86);
  }

  @include mq(tabletp) {
    display: none;
  }
}

.menu__toggle__icon {
  position: relative;

  span,
  span:before,
  span:after {
    content: "";
    display: block;
    width: 18px;
    height: 3px;
    @include transition(all 0.12s);
    background: $accent-color;
    border-radius: 4px;
  }

  span:before {
    position: absolute;
    top: -6px;
  }

  span:after {
    position: absolute;
    bottom: -6px;
  }
}

.menu--open .menu__toggle__icon {
  span {
    background-color: transparent !important;
  }

  span:before,
  span:after {
    top: 0;
  }

  span:before {
    @include rotate(45);
  }

  span:after {
    @include rotate(-45);
  }
}

.menu--open .header__menu {
  opacity: 1;
  visibility: visible;
  top: 0px;
}

.menu__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 120px;

  .menu__list__item {
    margin-bottom: 24px;
  }

  .menu__list__item__link,
  .menu__cta__link {
    font-size: $h4-size-small;
    font-weight: $h4-weight;
    line-height: $h4-lineheight;
    letter-spacing: $h4-spacing-small;
    text-transform: none;

    &.active-link {
      color: $accent-color;
    }
  }

  .menu__cta__link {
    color: $accent-color;
  }

  @include mq(tabletp) {
    margin-top: 0;
    flex-direction: row;

    .menu__list__item {
      margin-bottom: 0px;

      &:not(:first-child) {
        margin-left: 50px;
      }
    }

    .menu__list__item__link {
      font-size: 14px;
      line-height: 18px;
      display: inline-block;
      color: $text-dark-color;
      font-weight: bold;
      letter-spacing: $button-spacing;
      text-transform: uppercase;
    }

    .menu__cta__link {
      display: none;
    }
  }
}