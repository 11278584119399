// - - - - - - - - - - Foundations

@import "_reset";
@import "_mixins";
@import "_plugins";



// - - - - - - - - - - Variables


// Colors

$background-color: #ffffff;
$background-alt-color: #f4f5f6;
$border-color: #ECEDEF;

$text-dark-color: #202224;
$text-medium-color: #6C7A89;
$text-light-color: #AEB3BB;

$accent-color: #09d2bd;
$azure-color: #26afe7;

$error-color: #D64541;


// Fonts

$body-font: "Lato Roboto", sans-serif;
$title-font: Lato, sans-serif;

$regular-weight: 400;
$bold-weight: 700;


// Typography

$sitetitle-small: 30px;
$sitetitle-medium: 40px;
$sitetitle-large: 45px;
$sitetitle-weight: 700;
$sitetitle-spacing: -0.02em;

$menu-small: 30px;
$menu-medium: 15px;
$menu-large: 17px;
$menu-weight: 600;
$menu-spacing: 0em;

$h1-size: 48px;
$h1-size-small: 39px;
$h1-weight: 300;
$h1-spacing: px;
$h1-spacing-small: px;
$h1-lineheight: 56px;
$h1-lineheight-small: 48px;

$h2-size: 44px;
$h2-size-small: 36px;
$h2-weight: 300;
$h2-spacing: px;
$h2-spacing-small: px;
$h2-lineheight: 50px;
$h2-lineheight-small: 44px;

$h3-size: 38px;
$h3-size-small: 30px;
$h3-weight: 300;
$h3-spacing: px;
$h3-spacing-small: px;
$h3-lineheight: 48px;
$h3-lineheight-small: 36px;

$h4-size: 32px;
$h4-size-small: 28px;
$h4-weight: 300;
$h4-spacing: px;
$h4-spacing-small: px;
$h4-lineheight: 36px;

$h5-small: 17px;
$h5-medium: 20px;
$h5-large: 20px;
$h5-weight: 300;
$h5-spacing: 0em;
$h5-lineheight: 1.6;

$h6-small: 17px;
$h6-medium: 20px;
$h6-large: 20px;
$h6-weight: 300;
$h6-spacing: 0em;
$h6-lineheight: 1.6;

$subtitle-size: 22px;
$subtitle-size-small: 20px;
$subtitle-weight-regular: 400;
$subtitle-weight-heavy: 500;
$subtitle-spacing: 0.16px;
$subtitle-spacing-small: 0.2px;
$subtitle-lineheight: 32px;
$subtitle-lineheight-small: 28px;

$p-size: 18px;
$p-size-small: 17px;
$p-weight: 400;
$p-spacing: 0.04px;
$p-lineheight: 28px;

$p-small-size: 16px;
$p-small-size-small: 15px;
$p-small-weight: 400;
$p-small-spacing: 0px;
$p-small-lineheight: 24px;

$label-size: 14px;
$label-small: 12px;
$label-weight: 500;
$label-spacing: 0.8px;
$label-spacing-small: 0.5px;
$label-lineheight: 16px;

$footnote-size: 13px;
$footnote-weight: 400;
$footnote-spacing: -0.04px;
$footnote-lineheight: 12px;

$blockquote-small: 18px;
$blockquote-medium: 20px;
$blockquote-large: 22px;
$blockquote-weight: 300;
$blockquote-spacing: -0.02em;
$blockquote-lineheight: 1.6;

$button-weight: 500;
$button-spacing: 0.4px;


// Logo
$logo-width: 250px;
$footer-logo-width: 250px;


// Post details
$grid-spacing: 10px;


// Base URL
$baseurl: '';



// - - - - - - - - - - Global styles

@import "_basic";
@import "_typography";
@import "_buttons";
@import "_structure";


@import "_pages/_page";
@import "_pages/_page__blog";
@import "_pages/_page__post";
@import "_pages/_page__project";

@import "_includes/_component__header";
@import "_includes/_component__footer";

@import "_includes/_syntax";
@import "_includes/_gallery";
@import "_includes/_component__contact-form";
@import "_includes/_component__post-card";
@import "_includes/_section__about";
@import "_includes/_section__recent-posts";



// // - - - - - - - - - - Section styles

@import "_includes/_section__listing";
@import "_includes/_section__hero";
@import "_includes/_section__tiers";

.code-block {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.copy-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 14px;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s, transform 0.2s;
}

.copy-icon:hover {
  opacity: 1;
  transform: scale(1.1);
}