// - - - - - - - - - - - - - - - - - -

// Basics

// - - - - - - - - - - - - - - - - - -


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Basics

html, body {
	height: 100%;
}

body {
	background: $background-color;
	font-family: $body-font;
	font-weight: $regular-weight;
	font-style: normal;
	color: $text-dark-color;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

hr {
	border: 0;
	height: 2px;
	background-color: $border-color;
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Page loading

.page-loader {
	display: none;
}

.page {
	@include transition(all 0.6s cubic-bezier(0.2, 0.3, 0, 1));
}

section:not(.hero) {
	@include transition(all 0.6s cubic-bezier(0.2, 0.3, 0, 1));
}

body.loading {

	.loader {
		opacity: 1;
		visibility: visible;
		@include transition(all 0.4s ease-in-out 1s);
	}

	.page {
		opacity: 0;
		visibility: hidden;
		@include transition(all 0.4s ease-in-out, transform 0s ease-in-out 0.4s);
	}

	section:not(.hero) {
		@include translate(0,30px);
		@include transition(all 0.4s ease-in-out, transform 0s ease-in-out 0.4s);
	}

	.footer {
		opacity: 0;
		visibility: hidden;
		@include transition(all 0.4s ease-in-out);
	}
}

.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	@include translate(-50%,-50%);
	width: 60px;
	height: 15px;

	opacity: 0;
	visibility: hidden;
	@include transition(all 0.2s ease-in-out);

	svg {
		width: 100%;
		height: 100%;
		fill: rgba($text-dark-color,0.2);
	}
}



// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Buy CTA

.buy-cta {
	position: fixed;
	z-index: 10;
	bottom: 0;
	right: 0;
}

.buy-cta__link {
	display: block;
	position: relative;
	width: 40px;
	height: 40px;
	background: #dc5b5d;

	@include mq(tabletp) {
		width: 60px;
		height: 60px;
	}

	svg {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		@include translate(-50%,-50%);
		fill: #fff;

		@include mq(tabletp) {
			width: 30px;
			height: 30px;
		}
	}

	&:hover {

		.buy-cta__text {
			@include translate(0,0);
			opacity: 1;
		}
	}
}

.buy-cta__text {
	position: absolute;
	top: 0;
	right: 40px;
	background: #dc5b5d;
	padding: 14px 10px 13px 17px;
	color: #fff;
	font-size: 13px;
	line-height: 1;
	white-space: nowrap;

	@include transition(all 0.15s ease-in-out);
	@include translate(0,20px);
	opacity: 0;

	@include mq(tabletp) {
		right: 60px;
		padding: 22px 14px 23px 25px;
		font-size: 15px;
		@include translate(0,30px);
	}
}





// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Inputs & Input Groups


input[type="text"],
input[type="email"],
textarea {
	background-color: white;
  display: block;
  border: 0;
  border-bottom: 1px solid $border-color;
  @extend p;
	padding: 12px 0 16px;

	&:focus {
		border-bottom-color: $text-dark-color;
	}

	&:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
	}

	&::placeholder {
		color: $text-light-color;
		opacity: 1;
	}

	&:-ms-input-placeholder {
		color: $text-light-color;
	}

	&::-ms-input-placeholder {
		color: $text-light-color;
	}
}

.input-group {
	padding-top: 8px;
	margin-bottom: 24px;

	input,
	textarea {
		width: 100%;
	}
}

.input-submit {
	display: flex;
	justify-content: flex-end;
}

// Youtube-responsive
.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;
	width: 100%;
}

.video-container iframe, .video-container object, .video-container embed, .video-container video {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	border-top: 5px solid $azure-color;
	padding-bottom: 30px;

}

.mainvideo .video-container iframe, .mainvideo .video-container object, .mainvideo .video-container embed, .mainvideo .video-container video  {
	border: none;
	padding: none;
}