// - - - - - - - - - - - - - - - - - -

// Buttons

// - - - - - - - - - - - - - - - - - -


// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Basics

.button {
  &--text {
    font-family: $title-font;
    line-height: 18px;
    display: inline-block;
    font-size: 13px;
    color: $text-dark-color;
    font-weight: $button-weight;
    letter-spacing: $button-spacing;
    text-transform: uppercase;
    cursor: pointer;

    &.--right {
      --color-var: #{$text-dark-color};

      &::after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        mask: url($baseurl + "/images/icons/arrow-right-accent.svg") right center no-repeat;
        -webkit-mask: url($baseurl + "/images/icons/arrow-right-accent.svg") right center no-repeat;
        margin-left: 4px;
        background-color: var(--color-var);
      }

      &:hover {
        transform: translateX(2px);
        opacity: 0.7;
      }
    }

    &.--left {
      --color-var: #{$text-dark-color};

      &::before {
        content: "";
        display: inline-block;
        width: 20px;
        height: 10px;
        mask: url($baseurl + "/images/icons/arrow-right-accent.svg") right center no-repeat;
        -webkit-mask: url($baseurl + "/images/icons/arrow-right-accent.svg") right center no-repeat;
        margin-right: 4px;
        background-color: var(--color-var);
        transform: rotate(180deg);
      }

      &:hover {
        transform: translateX(-2px);
        opacity: 0.7;
      }
    }

    &--accent {
      @extend .button--text;
      color: $accent-color;

      &.--right::after,
      &.--left::before {
        background-color: $accent-color;
      }
    }

    &:hover {
      color: $accent-color;
    }
  }

  &--fill {
    @extend .button--text;
    color: white;
    background-color: $accent-color;
    padding: 13px 20px 14px;
    border-radius: 40px;

    &.--large {
      font-size: 24px;
      text-align: center;
      line-height: normal;
    }

    &:hover {
      color: white;
      background-color: darken($color: $accent-color, $amount: 5);
    }

    &:active {
      transform: scale(0.98);
    }
  }

  &--white {
    @extend .button--text;
    color: $accent-color;
    background-color: white;
    padding: 13px 20px 14px;
    border-radius: 40px;

    &.--large {
      font-size: 24px;
      text-align: center;
      line-height: normal;
    }

    &:hover {
      color:$accent-color;
      background-color: darken($color: white, $amount: 10);
    }

    &:active {
      transform: scale(0.98);
    }

    &.--border {
      border: 1px solid $accent-color;
    }
  }

  &--close-modal {
    background: url($baseurl + "/images/icons/arrow-cross-accent.svg") transparentize($accent-color, 0.92) center center no-repeat;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:active {
      background-color: transparentize($accent-color, 0.86);
    }
  }

  @include mq(tabletp) {
    &--text {
      font-size: 14px;
    }
  }
}
