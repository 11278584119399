// - - - - - - - - - - - - - - - - - -

// Footer

// - - - - - - - - - - - - - - - - - -

.footer {
	@include transition(all 0.4s ease-in-out 0.8s);
	padding: 64px 0;

	.footer__logo {
		width: $footer-logo-width;
		justify-self: center;
	}

	.footer__socials {
		justify-self: center;
	}

	.footer__title {
	    font-family: $title-font;
	    font-size: $sitetitle-small;
	    font-weight: $sitetitle-weight;
	    letter-spacing: $sitetitle-spacing;
	    line-height: 1;
	    color: $text-dark-color;

	    @include mq(tabletp) {
	      font-size: $sitetitle-medium;
	    }

	    @include mq(laptop) {
	      font-size: $sitetitle-large;
	    }

	    &:hover {
	      color: $accent-color;
	    }
	}
	
	.footer__copyright {

		@include mq(tabletp) {
			justify-self: center;
			align-self: center;
			text-align: right;
		}

		p {
			margin-bottom: 10px;
		}
	}

	.footer__address {
		
		p {
			margin-bottom: 2px;
		}
	}

	@include mq(tabletp) {
		padding: 80px 0;

		.wrap {
			grid-template-columns: 1fr 1fr;
		}

		.footer__logo {
			justify-self: start;
		}

		.footer__socials {
			justify-self: end;
		}
		
		.footer__copyright {
			justify-self: end;
		}

		.footer__address {
			justify-self: start;
		}
	}
}